<template>
  <div class="d-flex align-center fill-height">
    <v-col
      cols="4"
      class="d-none d-md-flex justify-start justify-xl-end pt-16 pr-xl-16"
    >
      <!-- test -->
      <img
        class="tumbleweed mt-1 mt-lg-3 mt-xl-7"
        src="https://image.flaticon.com/icons/png/512/1171/1171228.png"
        alt=""
      >
      <!-- test -->
    </v-col>
    <v-col
      md="4"
      cols="12"
      class="cardtext--text d-flex flex-column align-center"
    >
      <p class="error-code font-weight-bold">
        404
      </p>
      <p class="mt-n7 mt-sm-n9 mt-lg-n12 mt-xl-n14 subtitle-1">
        Упс... сторінку не знайдено
      </p>
      <v-btn
        outlined
        :block="$vuetify.breakpoint.xsOnly"
        color="success"
        class="px-5 font-weight-bold mt-2"
        @click="clickHandler"
      >
        На головну
        <span class="d-none d-sm-block">
          сторінку
        </span>
      </v-btn>
    </v-col>
    <v-col
      cols="4"
      class="d-none d-md-block"
    />
  </div>
</template>

<script>
import pageLoaderMixin from '../mixins/pageLoaderMixin';

export default {
  name: 'LostPage',
  title: '404',
  mixins: [pageLoaderMixin],
  mounted() {
    this.hidePagePreloader();
  },
  methods: {
    clickHandler() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .error-code {
    font-size: 8em;
    font-family: 'Lato', sans-serif;
    letter-spacing: 7px;

    @media (min-width: 600px) {
      font-size: 10em;
    }

    @media (min-width: 1264px) {
      font-size: 12em;
    }

    @media (min-width: 1904px) {
      font-size: 14em;
    }
  }

  .tumbleweed {
    width: 80%;

    @media (min-width: 1264px) {
      width: 70%;
    }

    @media (min-width: 1904px) {
      width: 50%;
    }
  }
</style>
